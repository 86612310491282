import React from 'react'
import { NavLink } from 'react-router-dom'
import useDocumentTitle from '../components/useDocumentTitle'

const Error404 = () => {
    useDocumentTitle('Oops 404 Error ⛔⛔⛔');
    return (
        <div id='introsection' className='d-flex justify-content-center align-items-center flex-column'>
            <h1>Error 404</h1>
            <NavLink to='/'><button className='btn btn-success'>Back to Home</button></NavLink>
        </div>
    )
}

export default Error404