import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import useDocumentTitle from '../components/useDocumentTitle'

const Home = () => {
  useDocumentTitle('KaviUIX | Home 🏠');
  return (
    <div className="row " id='home-container'>
      <div className="col-lg-6 d-flex justify-content-center flex-column" id='introsection'>
        <h1>Hi There!</h1>
        <h1 className='namehead'>I'm KaviPrakash</h1>
        <h2 className='workhead mt-3'>I'm a <TypeAnimation
          sequence={[
            'UI/UX Designer',
            1000,
            'Freelancer',
            1000,
          ]}
          wrapper="span"
          speed={50}
          style={{ display: 'inline-block' }}
          repeat={Infinity}
        /></h2>
        <button className='button1 mt-4'>Resume</button>
      </div>
      <div className="col-lg-6">

      </div>
    </div>
  )
}

export default Home