import React from 'react'
import useDocumentTitle from '../components/useDocumentTitle'


const Contact = () => {
  useDocumentTitle('KaviUIX | Contact 📞');
  return (
    <div id='introsection'>Contact</div>
  )
}

export default Contact