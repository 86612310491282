import React from 'react';
import { NavLink } from 'react-router-dom';
import DarkMode from './DarkMode/DarkMode/DarkMode';

const Navbar = () => {
  return (
    <>
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="/" id='link-navbar'>KaviUIX</a>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav nav-pills nav-fill justify-content-center">
              <li class="nav-item">
                <NavLink className='nav-link' id='link-navbar' to="/">Home</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className='nav-link' id='link-navbar' to="/about">Bio</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className='nav-link' id='link-navbar' to="/education">Education & Experience</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className='nav-link' id='link-navbar' to="/skills">Skills</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className='nav-link' id='link-navbar' to="/projects">Projects</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className='nav-link' id='link-navbar' to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
          <div className="d-flex">
            <DarkMode />
          </div>
        </nav>
    </>
  )
}

export default Navbar