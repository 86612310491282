import React from 'react'
import useDocumentTitle from '../components/useDocumentTitle'


const Skills = () => {
  useDocumentTitle('KaviUIX | Skills 🤹‍♂️');
  return (
    <div id='introsection'>Skills</div>
  )
}

export default Skills