import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle'

const About = () => {
  useDocumentTitle('KaviUIX | About (or) Bio 👦');
  return (
    <div className="row " id='bio-container'>
      <div className="col-lg-6" id='introsection'>
        <div id="bio1">
          <h1>Bio</h1>
          <p>I’m a UX/UI designer with an interior design background,
            a passion for creating digital products and user-centered
            digital experiences for everyone to use and enjoy.</p>
        </div>
        <div id="bio2">
          <h1>Hiring Me: A Strategic Move</h1>
          <p>As a UI/UX designer, I provide your team with a strategic edge.
            My keen attention to detail and dedication to user-centered design align
            seamlessly with your organization's objectives.</p>
        </div>
        <div id="bio3">
          <button className='btn button2 my-4 me-5'>Innovative Design Solutions</button>
          <button className='btn button2 my-4 me-5'>Passion for Design Excellence</button>
          <button className='btn button2 my-4 me-5'>Innovative Design Solutions</button>
          <button className='btn button2 my-4 me-5'>Passion for Design Excellence</button>
          <button className='btn button2 my-4 me-5'>Innovative Design Solutions</button>
          <button className='btn button2 my-4 me-5'>Passion for Design Excellence</button>
        </div>
      </div>
      <div className="col-lg-6">
      </div>
    </div>
  )
}

export default About