import React from 'react'
import useDocumentTitle from '../components/useDocumentTitle'


const Projects = () => {
  useDocumentTitle('KaviUIX | Projects 📄');
  return (
    <div id='introsection'>Projects</div>
  )
}

export default Projects