import React from 'react'
import useDocumentTitle from '../components/useDocumentTitle'


const Education = () => {
  useDocumentTitle('KaviUIX | Education & Experience 📚');
  return (
    <div id='introsection'>Education</div>
  )
}

export default Education